import React, { useState, useEffect } from 'react'
import FormData from 'form-data'
import { RxReset } from 'react-icons/rx'
import { AiFillFileAdd } from 'react-icons/ai'
import { useNotification } from '../context/NotificationProvider'

export const defaultPhrasal = {
    _id: "",
    verb: "",
    meaning: "",
    translations: ""
}

export default function VerbForm({ onSubmit, initialVerb, buttonTitle }) {

    const [phrasalInfo, setPhrasalInfo] = useState({ ...defaultPhrasal })

    const { updateNotification } = useNotification()

    useEffect(() => {
        setPhrasalInfo({ ...initialVerb })
    }, [initialVerb])

    const handleChange = ({ target }) => {
        const { value, name } = target

        const newPhrasal = { ...phrasalInfo, [name]: value }
        setPhrasalInfo({ ...newPhrasal })

        if (name === 'verb') {
            if (value.match(/\d/)) {
                return updateNotification("error", "Verb cannot contain any numbers")
            }
        }

        localStorage.setItem("phrasalVerb", JSON.stringify(newPhrasal))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const { verb, meaning, translations } = phrasalInfo

        if (!verb?.trim()) return updateNotification("error", "There is no verb here ( ఠ్ఠ‸ఠ్ఠ )")
        if (!meaning?.trim()) return updateNotification("error", "There is no meaning ໒( •́ ∧ •̀ )७")
        if (!translations?.trim()) return updateNotification("error", "There are no translations here ಠ╭╮ಠ")

        const formData = new FormData()
        const finalVerb = {
            verb: verb.charAt(0).toUpperCase() + verb.slice(1)
            , meaning: meaning.charAt(0).toUpperCase() + meaning.slice(1)
            , translations: translations.charAt(0).toUpperCase() + translations.slice(1)
        }
        for (let key in finalVerb) {
            formData.append(key, finalVerb[key])
        }

        onSubmit(finalVerb)
        resetForm()
    }

    const resetForm = () => {
        setPhrasalInfo({ ...defaultPhrasal })
        localStorage.removeItem("phrasalVerb")
    }

    const { verb, meaning, translations } = phrasalInfo

    return (

        //Full form for adding a verb to the backend
        <form
            onSubmit={handleSubmit}
            className="space-y-4 p-2 h-screen flex flex-col">

            {/*//*Title and submit */}

            <div className="flex items-center justify-between pl-2 pr-2">
                <h1 className="text-xl font-semibold text-green-900">
                    {buttonTitle + " Phrasal Verb"}
                </h1>
                <div className="flex items-center">
                    <button
                        onClick={resetForm}
                        type="button" className="flex items-center space-x-6 text-white 
                    px-6 ring-1 ring-green-600 m-1 rounded h-10 bg-teal-500
                    hover:bg-teal-700 hover:ring-green-700 transition">
                        <RxReset />
                        Reset
                    </button>
                    {/*<button
                        type="button"
                        className="flex items-center space-x-4 text-white 
                    px-6 ring-1 ring-green-600 m-1 rounded h-10 bg-teal-500
                    hover:bg-teal-700 hover:ring-green-700 transition">
                        <AiFillEye />
                        View
    </button>*/}
                    <button className="flex items-center space-x-4 text-white
                     px-16 ring-1 ring-green-200 m-1 rounded h-10 bg-teal-600
                     hover:bg-teal-900 hover:ring-green-300 transition">
                        <AiFillFileAdd />
                        {buttonTitle}
                    </button>
                </div>
            </div>

            {/*//*Check Box for revised*/}

            {
                /*<div>
                    <input id="revised" type="checkbox" hidden />
                    <label htmlFor="revised" className="select-none flex items-center space-x-4 text-teal-800 cursor-pointer
                    group">
                        <div className="w-4 h-4 rounded-full border-2 border-teal-600 flex 
                        items-center justify-center group-hover:border-teal-600">
                            <div className="w-3 h-3 rounded-full border-2 bg-teal-800 group-hover:bg-teal-600" />
                        </div>
                        <span className="group-hover: text-teal-600">Revised</span>
                    </label>
                </div>*/
            }

            {/*//*Phrasal Verb Input */}

            <div className="space-y-2 p-2">
                <textarea
                    value={verb}
                    name="verb"
                    onChange={handleChange}
                    type="text"
                    className="text-xl outline-none resize-none focus:ring-1 focus:ring-teal-600 rounded p-2 w-full h-12 font-medium" placeholder="Phrasal Verb"></textarea>
                <textarea
                    value={meaning}
                    name="meaning"
                    onChange={handleChange}
                    id="meaning"
                    placeholder="Meaning/Examples"
                    className="text-lg resize-none outline-none focus:ring-1 focus:ring-teal-600 rounded p-2 w-full h-24 font-light"></textarea>
                <textarea
                    value={translations}
                    onChange={handleChange}
                    name="translations"
                    id="translations"
                    placeholder="Translations"
                    className="text-lg resize-none outline-none focus:ring-1 focus:ring-teal-600
                rounded p-2 w-full h-20 font-light"></textarea>
            </div>
        </form >
    )
}