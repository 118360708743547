import './css/Card.css'

export default function Card({ phrasal }) {

    if (!phrasal) return null
    const { verb, meaning, translations } = phrasal

    const handleClick = () => {
        const card = document.getElementById("card");

        if (card) {
            card.addEventListener("click", function (e) {
                card.classList.toggle('is-flipped');
            });
        }
    }

    return (
        <div className="card" id="card" onClick={handleClick}>
            <div className="card-front">
                <h1 className="text-3xl font-semibold text-teal-700 select-none">{verb}</h1>
            </div>
            <div className="card-back">
                <h1 className="text-lg font-light text-orange-500 p-4 select-none">{meaning}</h1>
                <h1 className="text-lg font-semibold text-teal-500 p-4 select-none">{translations}</h1>
            </div>
        </div>
    )
}