import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useSearch } from "../context/SearchProvider"

export default function SearchForm() {
    const [query, setQuery] = useState([])
    const { handleSearch, resetSearch, searchResult } = useSearch()


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!query.trim()) return;
        handleSearch(query)
    }

    const handleReset = (e) => {
        resetSearch()
        setQuery("")
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') resetSearch()
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className='relative'>
                <input
                    value={query}
                    onKeyDown={handleKeyDown}
                    onChange={({ target }) => setQuery(target.value)}
                    placeholder="Looking for a verb?"
                    className="border border-gray-500 outline-none rounded 
                p-1 focus:ring-1 ring-red-400 w-56" />
            </form>
            {searchResult.length
                ? <button
                    onClick={handleReset}
                    className="relative text-gray-700 left-48 bottom-7">
                    <AiFillCloseCircle />
                </button>
                : null}
        </div>

    )
}