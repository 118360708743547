import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { ImHome3, ImFileText2 } from "react-icons/im";
import { HiAcademicCap } from "react-icons/hi";
import logo from '../assets/images/logo-192x192.png'

const NavItem = ({ to, value, icon, closed }) => {

    //*Never forget to add a space in the end or the beginning of the string for the class to work properly

    const commonClasses = "items-center flex space-x-4 w-full p-2 block whitespace-nowrap "
    const activeClass = commonClasses + ' bg-teal-200 text-teal-900 '
    const inactiveClass = commonClasses + ' text-teal-500 '

    useEffect(() => {
        document.title = 'Phrasal Verbs';
    }, []);


    return (
        <NavLink
            to={to}
            className={({ isActive }) =>
                (isActive ? activeClass : inactiveClass)}
        >
            {icon}
            <span className={closed
                ? 'w-0 transition-width overflow-hidden'
                : 'w-full transition-width overflow-hidden'}>
                {value}
            </span>
        </NavLink>
    )
}

export default function NavBar({ closed }) {
    return (
        <nav>
            <div className="flex justify-center items-center align-middle">
                <img src={logo} alt="phrasal-verbs-logo" className="w-11 m-2" />
            </div>
            <ul>
                <li>
                    <NavItem closed={closed} to="/" value="Home" icon={<ImHome3 size={22} />} />
                </li>
                <li>
                    <NavItem closed={closed} to="/study" value="Study" icon={<HiAcademicCap size={22} />} />
                </li>
                <li>
                    <NavItem closed={closed} to="/create-verb" value="Add" icon={<ImFileText2 size={22} />} />
                </li>
            </ul>
        </nav>
    )
}