import React, { useState, useEffect } from 'react'
import VerbForm, { defaultPhrasal } from './VerbForm'
import { useNavigate } from 'react-router-dom'

import { useNotification } from '../context/NotificationProvider'
import { createPhrasal } from '../api/verbFrontCRUD'

export default function CreatePhrasal() {

    const [phrasalInfo, setPhrasalInfo] = useState(null)

    const { updateNotification } = useNotification()
    const navigate = useNavigate()

    const handleSubmit = async (data) => {
        const { error } = await createPhrasal(data)
        if (error) return updateNotification('error', error)

        navigate('/')
    }

    useEffect(() => {
        const result = localStorage.getItem("phrasalVerb")
        if (!result) return;

        const oldVerb = JSON.parse(result)
        setPhrasalInfo({ ...defaultPhrasal, ...oldVerb })
    }, [])

    return (
        <VerbForm onSubmit={handleSubmit} initialVerb={phrasalInfo} buttonTitle="Add" />
    )
}
