import React, { useEffect, useState } from 'react'
import { getPhrasalVerbs } from '../api/verbFrontCRUD'
import VerbStudyCard from './VerbStudyCard'
import { useSearch } from '../context/SearchProvider'

let pageNo = 0
const VERB_LIMIT = 20

const getPaginationCount = (length) => {
    const division = length / VERB_LIMIT
    if (division % 1 !== 0) {
        return Math.floor(division) + 1
    }
    return division
}

export default function StudyPage() {

    const { searchResult } = useSearch()
    const [phrasalVerbs, setVerbs] = useState([])
    const [totalVerbCount, setTotalVerbCount] = useState([])

    const pages = getPaginationCount(totalVerbCount)
    const pagesArray = new Array(pages).fill(' ')

    const fetchVerbs = async () => {

        //Variable MUST have the same name as the backend variables
        const { error, phrasalVerbs, phrasalCount } = await getPhrasalVerbs(pageNo, VERB_LIMIT)

        if (error) return console.log(error)

        setVerbs(phrasalVerbs)
        setTotalVerbCount(phrasalCount)
    }

    useEffect(() => {
        fetchVerbs()
    }, [])

    const fetchMoreVerbs = (index) => {
        pageNo = index
        fetchVerbs()
    }

    return (
        <div>
            <div className="text-center pb-10 pt-4 ">
                <h1 className="text-5xl font-normal text-teal-400 select-none text-center">Study Phrasal Verbs</h1>
            </div>
            <div className="grid grid-cols-3 gap-9 m-8">
                {searchResult.length ? searchResult.map(verb => {
                    return <VerbStudyCard key={verb.id} phrasal={verb} />
                })
                    : phrasalVerbs.map(verb => {
                        return <VerbStudyCard key={verb.id} phrasal={verb} />
                    })
                    // TODO: in case none are found, fix the pagination
                    //<h1 className="text-lg font-semibold text-blue-700">No results found :c</h1>
                }
            </div>
            {
                pagesArray.length > 1 && !searchResult.length
                    ? <div className="py-5 flex justify-center items-center">
                        {
                            pagesArray.map((_, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => fetchMoreVerbs(index)}
                                        className={
                                            index === pageNo
                                                ? 'text-green-700 border-b-2 border-b-green-700 pl-2 pr-2'
                                                : 'text-gray-500 pl-2 pr-2'
                                        }>
                                        {index + 1}
                                    </button>
                                )
                            })
                        }
                    </div>
                    : null
            }
        </div>
    )
}