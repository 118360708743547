import React, { useState, useEffect } from 'react'
import { getPhrasal } from '../api/verbFrontCRUD'
import VerbForm from './VerbForm'
import { useNotification } from '../context/NotificationProvider'
import NotFound from './NotFound.jsx'
import { updatePhrasal } from '../api/verbFrontCRUD'
import { useNavigate } from 'react-router-dom'

export default function UpdatePhrasal() {
    const url = window.location.href;
    const id = url.split("/").pop();


    const { updateNotification } = useNotification()
    const [notFound, setNotFound] = useState(false)

    const navigate = useNavigate()

    const [phrasalInfo, setPhrasalInfo] = useState(null)

    const fetchVerb = async () => {
        const { error, phrasal } = await getPhrasal(id)
        if (error) {
            console.log("error: ")
            console.log(error)
            setNotFound(true)
            return updateNotification('error', error)
        }
        console.log({ ...phrasal })
        setPhrasalInfo({ ...phrasal })
    }

    useEffect(() => {
        fetchVerb()
    }, [])

    const handleSubmit = async (data) => {
        const sure = window.confirm('Are you sure you want to edit this?')
        if (!sure) return;
        const { error } = await updatePhrasal(id, data)
        if (error) return updateNotification('error', error)

        navigate('/')
    }


    if (notFound) return <NotFound />

    return (
        <VerbForm onSubmit={handleSubmit} initialVerb={phrasalInfo} buttonTitle={"Update"} />
    )
}