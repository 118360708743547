import React from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function VerbCard({ phrasal, onDelete }) {

    if (!phrasal) return null
    const { id, verb, meaning, translations } = phrasal

    return (
        <div className="rounded-lg border border-teal-900 pl-8 pr-8 pt-4 pb-4 bg-white">
            <h1 className="text-2xl font-semibold text-teal-700">{verb}</h1>
            <h1 className="text-lg font-light text-orange-500 pt-4">{meaning}</h1>
            <h1 className="text-lg font-semibold text-teal-500 pt-4">{translations}</h1>

            <div className="flex space-x-3 justify-start pt-4">
                <Link to={`/update-verb/${id}`} className="w-8 h-8 rounded-full bg-teal-400 flex justify-center items-center">
                    <BsPencil />
                </Link>
                <button onClick={onDelete}
                    className="w-8 h-8 rounded-full bg-red-600 flex justify-center items-center">
                    <BsTrash />
                </button>
            </div>
        </div>
    );

}
