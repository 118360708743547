import React, { createContext, useContext, useState } from 'react'
import { searchPhrasal } from '../api/verbFrontCRUD'

const SearchContext = createContext()

export default function SearchProvider({ children }) {
    const [searchResult, setSearchResult] = useState([])

    const handleSearch = async (query) => {
        const { error, phrasalVerbs } = await searchPhrasal(query)
        if (error) return console.log(error)

        setSearchResult(phrasalVerbs)
    }

    const resetSearch = () => {
        setSearchResult([])
    }

    return (
        <SearchContext.Provider value={{ handleSearch, resetSearch, searchResult }}>
            {children}
        </SearchContext.Provider>
    )
}

export const useSearch = () => useContext(SearchContext)
