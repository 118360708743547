import React, { createContext, useContext, useEffect, useState, useRef } from 'react'

const NotificationContext = createContext()

export default function NotificationProvider({ children }) {
    const [notification, setNotification] = useState({
        type: "",
        value: ""
    })

    const [bgColor, setBgColor] = useState("bg-red-400")

    const notificationRef = useRef()

    const updateNotification = async (type, value) => {

        if (!type || !value) return;

        switch (type) {
            case 'error':
                setBgColor('bg-red-400')
                break;
            case 'warning':
                setBgColor('bg-orange-400')
                break;
            case 'success':
                setBgColor('bg-green-400')
                break;
            default:
                setBgColor('bg-green-400')
        }

        setNotification({ type, value });
        setTimeout(() => {
            setNotification({ type: '', value: '' });
        }, 3000)
    }

    useEffect(() => {
        notificationRef.current?.classList.remove('bottom-14', 'opacity-0')
        notificationRef.current?.classList.add('bottom-14', 'opacity-1')
    }, [notification.value])

    return (
        <div className="items-center justify-center">
            <NotificationContext.Provider value={{ updateNotification }}>
                {children}
            </NotificationContext.Provider>
            {notification.value
                ? <p
                    ref={notificationRef}
                    className={
                        bgColor +
                        " rounded-full p-2 mb-4 absolute right-1/3 bottom-14 text-white transition-bottom duration-150 ease-linear"
                    }
                >
                    {notification.value}</p>
                : null}
        </div>
    )
}

export const useNotification = () => useContext(NotificationContext)
