import client from "./client"

export const getPhrasalVerbs = async (pageNo, limit) => {
    try {
        const { data } = await client(`/bring?pageNo=${pageNo}&limit=${limit}`)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}

export const deletePhrasal = async (verbId) => {
    try {
        const { data } = await client.delete(`/${verbId}`)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}

export const searchPhrasal = async (query) => {
    try {
        const { data } = await client(`/search?verb=${query}`)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}

export const createPhrasal = async (formData) => {
    try {
        const { data } = await client.post(`/add`, formData)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}

export const updatePhrasal = async (_id, formData) => {
    try {
        const { data } = await client.put(`/${_id}`, formData)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}

export const getPhrasal = async (id) => {
    try {
        const { data } = await client(`/bring/${id}`)

        return data
    }
    catch (error) {
        const { response } = error
        if (response?.data) {
            return response.data
        }

        return { error: error.message || error }
    }
}