import React from 'react';
import './css/VerbStudyCard.css'
import Card from './Card'

export default function VerbStudyCard({ phrasal }) {

    if (!phrasal) return null

    return (
        <div className="flippable-card-container">
            <Card phrasal={phrasal} />
        </div>
    )
}
