import React, { useState } from 'react';
import Home from './components/Home'
import StudyPage from './components/StudyPage'
import UpdatePhrasal from './components/UpdatePhrasal'
import CreatePhrasal from './components/CreatePhrasal'
import NotFound from './components/NotFound'
import NavBar from './components/NavBar'
import SearchForm from './components/SearchForm';
import { Routes, Route } from 'react-router-dom'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'

export default function App() {
    const [closedNav, setClosedNav] = useState(false)

    const toggleNav = () => {
        setClosedNav(!closedNav)
    }

    const getNavWidth = () => closedNav ? "w-14" : "w-56"

    return (
        <div className="flex">
            {/*Navigation Section*/}
            <div className={getNavWidth() + " min-h-screen transition-width border border-r"}>
                <div className="sticky top-2">
                    <NavBar closed={closedNav} />
                </div>
            </div>

            {/*Content Section*/}
            <div className="flex-1 w-56 min-h-screen bg-gray-50">
                <div className="sticky top-1 w-1/2">
                    <div className="flex item-center p-3 space-x-2">
                        <button onClick={toggleNav}>
                            {closedNav ? <AiOutlineMenuUnfold size={25} /> : <AiOutlineMenuFold size={25} />}
                        </button>
                        <SearchForm />
                        {/*<h1 className="text-xs font-thin text-gray-900">Press 'esc' key to reset search</h1>*/}
                    </div>
                </div>
                <div className="max-w-screen-lg mx-auto">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/study" element={<StudyPage />} />
                        <Route path="/create-verb" element={<CreatePhrasal />} />
                        <Route path="/update-verb/:_id" element={<UpdatePhrasal />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
}